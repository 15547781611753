

import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { Supplier } from '@/types/supplier.d'
import { Area } from '@/types/common.d'
// import { phone, email } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'

@Component
export default class SupplierAdd extends Vue {
  @Ref('form') readonly form!: ElForm

  supplierForm: Supplier = {
    supplierName: '',
    supplierType: '',
    operationScope: '',
    supplierDesc: '',
    cooperateStatus: '',
    province: '',
    city: '',
    area: '',
    address: '',
    url: '',
    contactList: []
  }

  position: string[] = []
  options: Area[] = []
  private supplierTypeList = []

  rules = {
    supplierName: [
      { required: true, message: '请输入供应商名称', trigger: 'blur' }
    ],
    supplierType: [
      { required: true, message: '请选择供应商分类', trigger: 'change' }
    ],
    cooperateStatus: [
      { required: true, message: '请选择合作状态', trigger: 'change' }
    ],
    operationScope: [
      { required: true, message: '请输入经营范围', trigger: 'blur' }
    ]
    // contactList: [
    //   { required: true, message: '请添加联系人', trigger: ['change'] }
    // ],
    // name: [
    //   { required: true, message: '请输入姓名', trigger: ['blur', 'change'] }
    // ],
    // department: [
    //   { required: true, message: '请输入部门', trigger: ['blur', 'change'] }
    // ],
    // post: [
    //   { required: true, message: '请输入职位', trigger: ['blur', 'change'] }
    // ],
    // telephone: [{ required: true, message: '请输入联系号码', trigger: ['blur', 'change'] }, { validator: phone, trigger: ['blur', 'change'] }],
    // mail: [{ required: true, message: '请输入邮箱', trigger: ['blur', 'change'] }, { validator: email, trigger: ['blur', 'change'] }]
  }

  created () {
    this.supplierForm.supplierId = this.$route.query.id as string
    this.addContact()
    this.loadRegions()
    this.getSupplierTypeList()
    if (this.supplierForm.supplierId) {
      this.loadData()
    }
  }

  loadData () {
    this.$axios.get<Supplier>(this.$apis.supplier.getSupplier, {
      supplierId: this.supplierForm.supplierId
    }).then(res => {
      this.position = [res.province, res.city, res.area]
      res.contactList.forEach(item => {
        item.isEdit = false
      })
      this.supplierForm = res
    })
  }

  loadRegions () {
    this.$axios.get<Area[]>(this.$apis.common.getRegion).then(res => {
      this.options = res
    })
  }

  // 获取供应商分类列表
  getSupplierTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'supplierType' }).then(res => {
      this.supplierTypeList = res.supplierType
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        const data: Supplier = JSON.parse(JSON.stringify(this.supplierForm))
        const supplierId = this.supplierForm.supplierId
        data.contactList.forEach(item => {
          delete item.isEdit
        })
        const url = supplierId ? this.$apis.supplier.updateSupplier : this.$apis.supplier.insertSupplier
        this.$axios.post(url, data).then(() => {
          this.$router.back()
        })
      }
    })
  }

  @Watch('position')
  positionChanged (val: string[]) {
    this.supplierForm.province = val[0]
    this.supplierForm.city = val[1]
    this.supplierForm.area = val[2]
  }

  save (index: number) {
    let valid = true
    const arr = [`contactList[${index}].name`, `contactList[${index}].department`, `contactList[${index}].post`, `contactList[${index}].telephone`, `contactList[${index}].mail`]
    // 判断联系人是否通过效验
    this.form.validateField(arr, (errorMessage) => {
      if (errorMessage) {
        valid = false
      }
    })
    if (valid) {
      this.supplierForm.contactList[index].isEdit = false
    }
  }

  addContact () {
    this.supplierForm.contactList.push({
      name: '',
      department: '',
      post: '',
      telephone: '',
      mail: '',
      isEdit: true
    })
  }

  removeContact (index: number) {
    this.supplierForm.contactList.splice(index, 1)
  }
}
